/*NEW APPLY CV POPUP START*/

$c_dark-blue: #0e1127;
$c_dark-gray: #343434;
$c_light-gray: #dce1e4;

.popup-center {
  position: fixed;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 840px;
  min-height: 500px;
  height: max-content;
  max-height: 90%;
  padding: 60px 70px;
  overflow: auto;
  overflow-x: hidden;
  justify-content: normal;
  align-items: normal;

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background: $color_white;
    border-radius: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: $c_dark-gray;
    border-radius: 5px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: $color_white;
  }

  .title-popup {
    margin-bottom: 50px;
  }

  .popup-form {
    width: 100%;
  }

  .checkBox {
    grid-column: 1 / span 2;
    margin: 10px 0 16px;

    .check-title {
      padding-left: 30px;
      font-size: 16px;
      color: $c_dark-gray;

      &:before {
        width: 18px;
        height: 18px;
        border: 3px solid #2d303f;
        top: 0;
        bottom: 0;
        margin: auto;
      }

      a {
        color: $color_blue;
        text-decoration: none;
        position: relative;

        &:before {
          content: "";
          position: absolute;
          bottom: 0;
          left: 0;
          width: 0;
          height: 2px;
          background-color: $color_blue;
          transition: 0.3s ease-in-out all;
        }

        &:hover {
          &:before {
            width: 100%;
          }
        }
      }
    }

    input:checked + .check-title:before {
      background: url("./../images/panel/checkbox-check.svg") no-repeat center;
      background-size: 20px;
    }
  }
}

.pc {
  &-inner {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 40px;
    grid-row-gap: 20px;
    width: 100%;
  }

  &-field {
    display: flex;
    flex-direction: column;

    .pc-label {
      margin-bottom: 10px;
    }

    input {
      width: 100%;
      border: 1px solid $c_light-gray;
      border-radius: 5px;
      padding: 16px;
      font-size: 15px;

      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus,
      &:-webkit-autofill:active {
        -webkit-box-shadow: 0 0 0 30px $color_white inset !important;
        -webkit-text-fill-color: $c_dark-gray;
      }
    }
  }

  &-cv-field {
    display: flex;
    flex-direction: column;
    grid-column: 1 / span 2;

    input[type="file"] {
      display: none;
    }

    .cv_file_name {
      color: $color_blue;
    }
  }

  &-label {
    font: 16px/130% "BigCityGrotesquePro", sans-serif;
    color: $c_dark-gray;
  }

  &-btn {
    font: 16px/130% "BigCityGrotesquePro", sans-serif;
    background-color: $c_dark-blue;
    color: $color_white;
    border: none;
    outline: none;
    border-radius: 5px;
    padding: 16px;
    cursor: pointer;
    transition: 0.3s ease-in-out all;
    position: relative;

    &:before {
      content: "";
      z-index: -1;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      border: 4px solid hsl(236, 32%, 26%);
      transform-origin: center;
      transform: scale(1);
      border-radius: 5px;
    }

    &:hover {
      &:before {
        transition: all 1s ease-in-out;
        transform-origin: center;
        transform: scale(1.2);
        opacity: 0;
      }
    }
  }

  &-captcha {
    grid-column: 1 / span 2;
  }
}

.custom-file-upload {
  border: 1px dashed $c_light-gray;
  border-radius: 5px;
  padding: 19px;
  display: flex;
  justify-content: center;
  cursor: pointer;
  position: relative;
  transition: 0.3s ease-in-out all;

  span {
    transition: 0.3s ease-in-out all;
  }

  &:hover {
    background-color: var(--color-light-blue);
    color: var(--color-white);
    span {
      color: $color_white;
    }
  }
}

/*/ NEW APPLY CV POPUP END*/

@media only screen and (max-width: 1024px) {
  .popup-center {
    width: 95%;
    min-height: auto;
    height: 90%;
    max-height: none;
    padding: 45px;

    .title-popup {
      margin-bottom: 30px;
    }

    .checkBox {
      margin: 0;
    }
  }

  .pc {
    &-inner {
      grid-gap: 20px;
    }

    &-field {
      input {
        padding: 12px;
      }
    }

    &-btn {
      padding: 12px;
    }
  }

  .custom-file-upload {
    padding: 15px;
  }
}

@media only screen and (max-width: 720px) {
  .pc {
    &-captcha {
      transform: scale(0.8);
      transform-origin: 0;

      & > div {
        width: 100%;
        height: 110px;
      }

      .g-recaptcha {
        width: 100%;
        height: 110px;

        & > div {
          width: 100% !important;
          height: 100% !important;
        }
      }

      iframe {
        width: 304px;
        height: 100%;
      }
    }
  }
}

@media only screen and (max-width: 550px) {
  .popup-center {
    width: 95%;
    height: 80%;
    padding: 20px 15px;

    .title-popup {
      margin-bottom: 40px;
      font-size: 18px;
    }

    .checkBox {
      margin: 0;
      grid-column: 1;

      .check-title {
        font-size: 15px;
      }
    }

    .close-popup {
      font-size: 20px;
      top: 20px;
      right: 15px;
    }
  }

  .pc {
    &-inner {
      grid-template-columns: 1fr;
      grid-gap: 20px;
    }

    &-field {
      input {
        padding: 11px;
      }

      .pc-label {
        margin-bottom: 7px;
      }
    }

    &-label {
      font-size: 15px;
    }

    &-cv-field {
      grid-column: 1;
      margin-top: 10px;
    }

    &-btn {
      padding: 15px;
      margin-top: 20px;
    }

    &-captcha {
      grid-column: 1;
      transform-origin: center;
      /*transform: scale(0.8);

        & > div {
        	width: 100%;
        	height: 110px;
        }*/

      .g-recaptcha {
        /*width: 100%;
            height: 110px;*/

        & > div {
          /*width: 100% !important;
                height: 100% !important;*/

          & > div {
            display: flex;
            justify-content: center;
          }
        }
      }

      /*        iframe {
        	width: 440px;
        	height: 100%;
        }*/
    }
  }

  .custom-file-upload {
    padding: 19px;
  }
}

@media only screen and (max-width: 360px) {
  .pc {
    &-captcha {
      transform: scale(0.7);
      overflow: hidden;

      & > div {
        height: 110px;
      }

      .g-recaptcha {
        height: 110px;
      }

      iframe {
        width: 300px;
      }
    }
  }
}
