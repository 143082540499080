:root {
    --color-black: #10161F;
    --color-dark: #31373E;
    --color-grey: #A5B4C1;
    --color-white: #fff;
    --color-blue: #004D95;
    --color-dark-blue: #002c5a;
    --color-navy: #002c5a;
    --color-light-blue: #009ED0;
    --color-orange: #FF5742;
    --color-green: #95DC00;
    --color-yellow: #FFEC00;
    --font-main: "Gotham", sans-serif;
    --header-height: 82px;
    --header-height-scrolled: calc(var(--header-height) - 5px);
    --bgcolor: #002c5a;
    --ytop: 0; /*needed for page-builder section alignment conneting*/
    --ybot: 0; /*needed for page-builder section alignment conneting*/
    --pb-initialized: 0px; /*needed  to init padding bottom for page builder section connection (JS)*/
}

.animated {
    opacity: 0;
}

.slide-left {
    opacity: 0;
    animation: slide-left 1.5s forwards;
}

.slide-right {
    opacity: 0;
    animation: slide-right 1.5s forwards;
}

.slide-down {
    opacity: 0;
    animation: slide-down 1.5s forwards;
}

.slide-up {
    opacity: 0;
    animation: slide-up 1.5s forwards;
}

.fade-in {
    opacity: 0;
    animation: fade-in 1.5s forwards;
}

.pulse {
    animation: pulse 0.3s forwards;
}

@keyframes slide-left {
    0% {
        opacity: 0;
        transform: translateX(20px);
    }

    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes slide-right {
    0% {
        opacity: 0;
        transform: translateX(-20px);
    }

    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes slide-up {
    0% {
        opacity: 0;
        transform: translateY(20px);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes slide-down {
    0% {
        opacity: 0;
        transform: translateY(-20px);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes fade-in {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes pulse {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.05);
    }

    100% {
        transform: scale(1);
    }
}
@font-face {
    font-family: 'icomoon';
    src:  url('../fonts/icomoon.eot?12gh4p');
    src:  url('../fonts/icomoon.eot?12gh4p#iefix') format('embedded-opentype'),
    url('../fonts/icomoon.woff2?12gh4p') format('woff2'),
    url('../fonts/icomoon.ttf?12gh4p') format('truetype'),
    url('../fonts/icomoon.woff?12gh4p') format('woff'),
    url('../fonts/icomoon.svg?12gh4p#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-mail:before {
    content: "\e90a";
}
.icon-phone:before {
    content: "\e90b";
}
.icon-cross:before {
    content: "\ea0f";
}
.icon-c-suite:before {
    content: "\e90d";
}
.icon-software:before {
    content: "\e90e";
}
.icon-electronics:before {
    content: "\e90f";
}
.icon-sales:before {
    content: "\e910";
}
.icon-scientific:before {
    content: "\e911";
}
.icon-semiconductor:before {
    content: "\e912";
}
.icon-technical-operations:before {
    content: "\e913";
}
.icon-technology:before {
    content: "\e914";
}
.icon-iconmonstr-paperclip-1:before {
    content: "\e909";
}
.icon-twitter:before {
    content: "\e900";
}
.icon-linkedin:before {
    content: "\e901";
}
.icon-instagram:before {
    content: "\e902";
}
.icon-facebook:before {
    content: "\e903";
}
.icon-arrow-down:before {
    content: "\e904";
}
.icon-arrow-right:before {
    content: "\e90c";
}
.icon-arrow-left:before {
    content: "\e905";
}
.icon-location:before {
    content: "\e906";
}
.icon-import-file:before {
    content: "\e907";
}
.icon-search:before {
    content: "\e908";
}
